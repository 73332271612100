@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.main-content {
    z-index: 999;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px;
    border-radius: 5px;
    animation: pulse 1s infinite;
}

.icon-svg {
    cursor: pointer;
    border: white 5px solid;
    border-radius: 43px;
}