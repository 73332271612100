.logo-container {
    @include flex-center;
  
    > * + * {
      margin-left: 3%;
    }
  }
  
  .logo-size {
    &--xxs {
      width: 115px;
    }
    &--xs {
      width: 233px;
    }
    &--md {
      width: 398px;
    }
    &--lg {
      width: 420px;
    }
    &--xlg {
      width: 500px;
    }
  }
  