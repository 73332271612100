.input-container {
    margin-bottom: 30px;
  
    &--error {
      border-color: $secondary;
    }
  
    &--relative {
      position: relative;
    }
  }
  
  .input-icon {
    &--left {
      display: grid;
      place-items: center;
      position: absolute;
      left: 17px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &--right {
      display: grid;
      place-items: center;
      position: absolute;
      right: 17px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  
  .input {
    &--error {
      display: block;
      color: $secondary;
      padding: 5px 0;
    }
  
    &--hint {
      font-size: .8rem;
      color: $quinary--xdark;
    }
  }
  