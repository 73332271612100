.popup-container {}

.popup-content {
  margin-top: 14px;
}

.popup-icon {
  &--top-left {
    float: left;
  }

  &--top-center {
    display: block;
    margin: 0 auto;
    max-width: 40%;
    padding-top: 15px;
  }

  &--top-right {
    float: right;
  }

  .popup-body-text {
    color: $quinary--xdark;
  }
}