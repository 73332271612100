  @mixin grid {
    display: grid;
    grid-gap: 1rem;  
  }

  @mixin flex-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  @mixin flex-start {
    @include flex-box;
    justify-content: flex-start;
  }
  
  @mixin flex-center {
    @include flex-box;
    justify-content: center;
  }
  
  @mixin flex-end {
    @include flex-box;
    justify-content: flex-end;
  }
  
  @mixin flex-between {
    @include flex-box;
    justify-content: space-between;
  }
  
  @mixin flex-around {
    @include flex-box;
    justify-content: space-around;
  }
  
  @mixin flex-evenly {
    @include flex-box;
    justify-content: space-evenly;
  }