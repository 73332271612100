.login-wrapper {
    @include flex-center;
    flex: 1;
    height: 100vh;
    flex-direction: row;

    .start-session__container {
        @include flex-center;
        position: relative;
        flex: 1;
        height: 100%;
    }

    .start-session-content__container {
        position: relative;
        width: 100%;
    }

    .showcase__container {
        flex: 1;
        height: 100%;
    }

    .title {
        margin-top: 81px;
        margin-bottom: 57px;
    }

    .button-submit {
        margin-bottom: 35px;
    }

    .button--toleft {
        float: left;
    }

    .button--toright {
        float: right;
    }

    .button-link {
        font-size: 1rem;

        &:hover {
            background-color: transparent;
        }
    }

    .oaauth-container {
        margin-bottom: 80px;
    }

    .oaauth-item {
        >*+* {
            margin-left: 5px;
        }
    }

    .button-oaauth-signup {
        font-size: 1.1rem;
    }

    .login-bg {
        background-image: url("../../assets/img/bg_login.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .maint-bg {
        background-image: url("../../assets/mantenimiento.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .signup-link {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
}