@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,600;0,700;1,400&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap");
@import "./colors.scss";
@import "./mixins.scss";
@import "./global.scss";
@import "./views/session.scss";
@import "./views/dashboard.scss";
@import "./views/static.scss";
@import "./components/logo.scss";
@import "./components/form.scss";
@import "./components/buttons.scss";
@import "./components/popup.scss";
@import "./components/counterByStatus.scss";
@import "./components/routing.scss";
@import "~intro.js/introjs.css";

*,
html {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    // color: $black; TODO cuidado con este color
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    // font-family: "PoppinsBold";
    scroll-behavior: smooth;
    font-weight: 500;

    body {
        .menu-aside__item-text {
            color: $quinary--xdark;
            margin-left: 10px;
        }

        .menu-aside__list {
            padding: 0;
            list-style: none;
        }

        .menu-aside__item-focus {
            background: $primary--light;
        }

        .content__container {
            height: 100%;
            display: grid;
            grid-template-columns: minmax(316px, 16.5%) 1fr;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $primary;
    font-weight: 500;
}

a {
    color: $primary;
    font-weight: 800;
    text-decoration: none;
    font-family: "Nunito", sans-serif;
    // font-family: "PoppinsBold";
    transition: 0.2s ease-in-out;

    &:hover {
        color: darken($color: $primary, $amount: 8);
    }
}

input,
select {
    padding: 15px 23px;
    background: $quinary--light;
    border: 2px solid $quinary;
    border-radius: 7px;
    width: 100%;

    &::placeholder {
        color: $quinary;
    }

    &:focus {
        outline-color: $primary;
    }

    &[type="checkbox"],
    [type="radio"] {
        width: max-content;
        margin-right: 5px;
    }

    &[type="file"] {
        opacity: 0;
        min-height: 300px;
        cursor: pointer;
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
    }
}

label {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
}

body {
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: $primary--light;
        outline: none;
        border-radius: 999rem;
    }
}