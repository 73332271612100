.counter-status__container {
    // padding: 26px 20px;    
    display: flex;
    width: 100%;
    height: 95px;
    border-radius: 10px;
}

.slick-slide {
    margin-right: 7px;
}

.slick-list {
    height: 110px;
}