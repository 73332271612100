.navlink {
    display: block;
    font-weight: 400;
    padding: 9px 0;
    cursor: pointer;
    transition: 0.2 ease-in-out;

    &:hover {
        color: $primary--light;
    }

    &--inactive {
        color: $quinary--xdark;
    }

    &--active {
        font-weight: 600;
        color: $primary--light;
        background-color: $quinary--light;
        border-radius: 5px;
        padding: 8px 5px;
    }
}